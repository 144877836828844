<template>
  <div :class="$style.container">
    <aside :class="$style.aside" v-if="$slots.aside">
      <div :class="$style.sticky">
        <slot name="aside" />
      </div>
    </aside>
    <div :class="$style.main"><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'AppLayoutColumns'
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  height: 100%;
  @include down(sm) {
    display: block;
  }
}
.aside {
  padding: 4em 2em 4em 4em;
  width: 38em;
  flex: 0 0 38em;
  height: 100%;
  @include down(md) {
    padding-right: 0;
    width: 32em;
    flex: 0 0 32em;
  }
  @include down(sm) {
    width: auto;
    height: auto;
    padding: 2em 1.6em 0;
  }
}
.sticky {
  position: sticky;
  top: calc(var(--app-header-height) + 2em);
  @include down(sm) {
    position: static;
  }
}
.main {
  flex: 1 1;
  //padding: 1.8em 3.3em 4em 4em;
  padding: 4em 3.6em 4em 4em;
  //padding: 4em 3.6em 4em 1.8em;
  z-index: 0;
  min-width: 0;
  @include down(sm) {
    padding: 2em 1.6em;
  }
}
</style>
